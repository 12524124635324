<template>
    <div class="relative d-flex align-items-end justify-content-center min-h-screen bg-resilience-red pt-4 sm:pt-0 text-light pb-3">
        <!-- <div class="h3 d-flex justify-content-center">日本の防災</div><div class="h5 d-flex justify-content-center">を</div> -->
         <div class="h2 d-flex justify-content-center">安否確認・防災備蓄管理・ＢＣＰ策定クラウドシステム</div>
    </div>
    <div class="relative d-flex justify-content-center min-h-screen bg-resilience-red sm:pt-0 text-light">
        <i id="logo_title" />
    </div>
    <div class="relative d-flex flex-column min-h-screen bg-resilience-red pt-3 sm:pt-0 text-light">
        <!-- <div class="h3 d-flex justify-content-center">持続型BCP策定システム</div> -->
    </div>
    <div class="relative d-flex justify-content-center min-h-screen bg-resilience-red sm:pt-0 text-light">
        <i id="title_image" />
    </div>
    <div class="relative d-flex justify-content-end min-h-screen">
        <label class="h6 color-resilience-red">このサイトは、岐阜県のデジタル・トランスフォーメーション推進補助金を受けて運営しています。</label>
    </div>

    <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
        <div class="h2 d-flex justify-content-center color-resilience-red">会員種別の選択</div>
    </div>
    <div class="relative d-flex flex-column min-h-screen mt-2">
        <div class="h5 d-flex justify-content-center color-resilience-red">システムを利用するためには会員登録が必要です</div>
    </div>
    <div class="container">
        <div class="row mt-5 sm:pt-0 d-flex justify-content-center align-items-stretch">
            <div class="col-sm-4 d-flex">
                <div class="card text-center cursor-pointer d-flex flex-column" @click="registerFree">
                    <div class="card-header bg-resilience-black">
                        <i id="free_member" class="member_image" />
                        <div class="h1 text-light">無償会員</div>
                    </div>
                    <div class="card-body">
                        <div class="h4 card-text fw-bold mb-5">
                            無償でシステムを<br />体験されたい方は
                        </div>
                        <div class="card-body bg-member_card">
                            20日間※の<br />トライアル期間のみ利用
                        </div> 
                        <div class="p-3">
                            入門レベルのBCP策定、策定結果の閲覧、安否確認ツール、BCPの運用サイクル、経営者による自己診断などが利用できます。
                        </div>
                    </div>
                    <div class="card-footer bg-resilience-black">
                        <div class="h2 text-light">
                            登録する
                        </div>
                    </div>
                </div>
                <div class="mt-3 d-md-none">
                    <div>※岐阜県内の事業者は、岐阜県DX補助事業特例措置により1ヶ月間です。</div>
                </div>
            </div>
            <div class="col-sm-4 d-flex">
                <div class="card text-center cursor-pointer d-flex flex-column" @click="registerPaid">
                    <div class="card-header bg-resilience-orange">
                        <i id="paid_member" class="paid_member_image" />
                        <div class="h1 text-light">有償会員</div>
                    </div>
                    <div class="card-body">
                        <div class="h4 card-text fw-bold mb-5">
                            すぐにシステムを<br />利用される方は
                        </div>
                        <div class="card-body bg-member_card">
                            年会費・従量課金<br />登録後すぐに利用
                        </div>
                        <div class="p-3">
                            入門、基本、中級、上級レベルのBCP策定、安否確認ツール、B&B災害応援協定ビジネスマッチングなど、すべての機能が利用できます。
                        </div>
                    </div>
                    <div class="card-footer bg-resilience-orange ">
                        <div class="h2 text-light">
                            登録する
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4" v-show="showFoundingFlg">
                <div class="card text-center cursor-pointer" @click="registerFounding">
                    <div class="card-header bg-resilience-light-blue">
                        <i id="cloud_founding_member" class="member_image" />
                        <div class="h1 text-light">クラファン会員</div>
                    </div>
                    <div class="card-body">
                        <div class="h4 card-text fw-bold mb-5">
                            クラウドファンディングの<br />リターンとして利用される方は
                        </div>
                        <div class="card-body bg-member_card">
                            登録時に受信した<br />クーポンコードで利用
                        </div>
                        <div class="p-3">
                            有償会員と同様、すべての機能が利用できます。次年度は、有償会員として継続利用ができます。
                        </div>
                    </div>
                    <div class="card-footer bg-resilience-light-blue">
                        <div class="h2 text-light">
                            登録する
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-1 d-flex justify-content-center ">
            <div class="col-sm-4 d-none d-md-block">
                <div>※岐阜県内の事業者は、岐阜県DX補助事業特例措置により1ヶ月間です。</div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4" v-show="showFoundingFlg"></div>
        </div>
    </div>
    <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
        <div class="h2 d-flex justify-content-center color-resilience-red">TOTONO-Lの資料はこちら</div>
    </div>
    <div class="relative d-flex flex-column min-h-screen mt-2">
        <div class="h5 d-flex justify-content-center color-resilience-red">サービスのご検討に役立つPDF資料をご用意しています</div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF(11)">
                    TOTONO-L「安否確認・防災備蓄品管理」資料ダウンロード
                </div>
                <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF(12)">
                    TOTONO-L「BCP・代替戦略・運用サイクル」資料ダウンロード
                </div>
                <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF(13)">
                    TOTONO-L 「会員種別・年会費・従量課金」資料ダウンロード
                </div>
                <!-- <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF(1)">
                        TOTONO-L　BCP策定資料ダウンロード
                </div>
                <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF(2)">
                        TOTONO-L　B＆B災害応援協定ビジネスマッチング資料ダウンロード
                </div>
                <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF(3)">
                        TOTONO-L  安否確認資料ダウンロード
                </div>
                <div class="btn btn-gray btn-pdf-download mt-3 py-4" @click="downloadPDF(4)">
                        TOTONO-L  会員システム・年会費資料ダウンロード
                </div> -->
            </div>
        </div>
    </div>
    <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
        <div class="h2 d-flex justify-content-center color-resilience-red">更新履歴</div>
    </div>
    <div class="relative d-flex flex-column min-h-screen mt-2">
        <div class="h5 d-flex justify-content-center color-resilience-red">TOTONO-Lの主な機能追加・変更等の更新履歴です</div>
    </div>
    <div class="container">
        <div class="row mb-3 ">
            <ul class="news-list">
                <li class="item" v-for="news in changeLogList" :key="news.id">
                    <div>
                        <p class="date">{{ this.$root.dayjs(news.notice_date).format('YYYY-MM-DD') }}</p>
                        <span class="badge rounded-pill bg-danger me-4" v-show="this.$root.dayjs().subtract(1, 'w') < this.$root.dayjs(news.notice_date)">New</span>
                        <p class="detail">{{ news.detail }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue'
import { useEnv } from '../function/useEnv';
import axios from 'axios';

export default {
    setup(){
        const changeLogList = ref([]);
        const { VITE_SHOW_FOUNDING_FLG } = useEnv();
        onMounted(()=>{
            const url = '/api/getChangeLog';

            axios.get(url).then(res =>{
                changeLogList.value = res.data.result.data;
            });

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            // クエリパラメータをコンソールに出力
            console.log(Object.fromEntries(urlParams.entries()).campaign);

            const url_test = new URL(window.location.href);
            const params = url_test.searchParams; // 修正箇所
            console.log(params.get("campaign"));
        });

        const showFoundingFlg = ref(VITE_SHOW_FOUNDING_FLG);
        const registerFree = () => {
            const url ='/register_free';

            location.href = url;
        }
        const registerPaid = () => {
            const url ='/register_paid';

            location.href = url;
        }
        const registerFounding = () => {
            const url ='/register_founding';

            location.href = url;
        }

        const downloadPDF = (kbn) => {
            const url_print = '/api/downloadDoc';

            let DocName;

            switch(kbn){
                case 1:
                    DocName = '1_TOTONO-L BCP策定資料';
                    break;
                case 2:
                    DocName = '2_TOTONO-L B_B災害応援協定ビジネスマッチング資料';
                    break;
                case 3:
                    DocName = '3_TOTONO-L 安否確認資料';
                    break;
                case 4:
                    DocName = '4_TOTONO-L 会員システム・年会費資料';
                    break;
                case 11:
                    DocName = '1_TOTONO-L_安否確認・防災備蓄品管理';
                    break;
                case 12:
                    DocName = '2_TOTONO-L_BCP・代替戦略・運用サイクル';
                    break;
                case 13:
                    DocName = '3_TOTONO-L_会員種別・年会費・従量課金';
                    break;
                default:
                    return;
            }

            axios.post(url_print, {
                'kbn': kbn,
            },{
                responseType: 'arraybuffer',
            }).then(res => {
                const data = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf'}));
                let link = document.createElement('a');
                link.href = data;
                link.download = DocName + '.pdf';
                link.click();

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            });
        }
        
        return {
            changeLogList,
            registerFree,
            registerPaid,
            registerFounding,

            downloadPDF,

            showFoundingFlg,
        };
    },
    data() {
        return{
        }
    },
    props: [
    ],
}
</script>

<style lang="scss" scoped>
/* 更新履歴 */
.news-list{
    list-style: none outside;
    margin: 0;
    padding: 0;
  }
  .news-list .item div{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    text-decoration: none;
    color: #404040;
    border-bottom: 1px solid #CCC;
    padding: 20px 20px;
  }
  .news-list .item:first-child div{
    border-top: 1px solid #CCC;
  }
  .news-list .item .date{
    margin: 0;
    min-width: 140px;
    color: #404040;
    padding: 0 20px 0 0;
  }
  .news-list .item .icon{
    margin: 0;
    min-width: 40px;
    padding: 0 20px 0 0;
  }
  .news-list .item .detail{
    margin: 0;
    padding-right: 50px;
    flex-grow: 1;
    position: relative;
    word-break: break-word;
  }

@media screen and (max-width: 767px){
    .news-list .item div{
        flex-wrap: wrap;
    }
    .news-list .item .date{
        min-width: 100px;
    }
    .news-list .item .detail{
        margin-top: 10px;
        position: absolute;
        left: 200px;
    }
  }
</style>