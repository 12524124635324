<template>
    <div class="container">
        <div class="relative d-flex flex-column min-h-screen pt-5 sm:pt-0 border-bottom border-2 border-color-resilience">
            <div class="h2 d-flex justify-content-center color-resilience-red">主な機能（TOTONO-Lが解決してくれること）</div>
        </div>
        <div class="container">
            <div class="row mt-5 pb-sm-5 d-flex align-items-stretch">
                <div class="col-sm-4 d-flex">
                    <div class="card text-center cursor-pointer d-flex flex-column" @click="showDetail(1)">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="top_image_4" class="top_image" />
                        </div>
                        <div class="card-body">
                            <div class="h4 card-text fw-bold mb-5">
                                平時活用・有事機能を実現した<br>「安否確認」ツール
                            </div>
                        </div>
                        <div class="card-footer bg-resilience-red cursor-pointer">
                            <div class="h5 text-light">
                                詳しく見る
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mt-3 mt-sm-0 d-flex">
                    <div class="card text-center cursor-pointer d-flex flex-column" @click="showDetail(2)">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="top_image_7" class="top_image_new" />
                        </div>
                        <div class="card-body">
                            <div class="h4 card-text fw-bold mb-5">
                                効率的な在庫管理を実現した<br>「防災備蓄品リスト」
                            </div>
                        </div>
                        <div class="card-footer bg-resilience-red cursor-pointer">
                            <div class="h5 text-light">
                                詳しく見る
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mt-3 mt-sm-0 d-flex">
                    <div class="card text-center cursor-pointer d-flex flex-column" @click="showDetail(3)">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="top_image_1" class="top_image" />
                        </div>
                        <div class="card-body">
                            <div class="h4 card-text fw-bold mb-5">
                                クラウド上で策定できる<br>上級レベルの「ＢＣＰ」
                            </div>
                        </div>
                        <div class="card-footer bg-resilience-red">
                            <div class="h5 text-light">
                                詳しく見る
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-0 mt-sm-5 sm:pt-0 d-flex align-items-stretch">
                <div class="col-sm-4 mt-3 mt-sm-0 d-flex">
                    <div class="card text-center cursor-pointer d-flex flex-column" @click="showDetail(4)">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="top_image_2" class="top_image" />
                        </div>
                        <div class="card-body">
                            <div class="h4 card-text fw-bold mb-5">
                                ヒト・モノ・コトの災害応援<br>協定先をマッチング検索
                            </div>
                        </div>
                        <div class="card-footer bg-resilience-red cursor-pointer">
                            <div class="h5 text-light">
                                詳しく見る
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mt-3 mt-sm-0 d-flex">
                    <div class="card text-center cursor-pointer d-flex flex-column" @click="showDetail(5)">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="top_image_8" class="top_image_new2" />
                        </div>
                        <div class="card-body">
                            <div class="h4 card-text fw-bold mb-5">
                                有事のスピード感をＵＰする<br>産官学ネットワーク
                            </div>
                        </div>
                        <div class="card-footer bg-resilience-red cursor-pointer">
                            <div class="h5 text-light">
                                詳しく見る
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 mt-3 mt-sm-0 d-flex">
                    <div class="card text-center cursor-pointer d-flex flex-column" @click="showDetail(6)">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="top_image_5" class="top_image" />
                        </div>
                        <div class="card-body">
                            <div class="h4 card-text fw-bold mb-5">
                                法人も個人事業主も<br />取り組みやすい料金設定
                            </div>
                        </div>
                        <div class="card-footer bg-resilience-red cursor-pointer">
                            <div class="h5 text-light">
                                詳しく見る
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-md-5 mt-3" v-if="false">
            <div class="row">
                <div class="col-md-6 p-md-5">
                    <div class="card">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="main-function-image-01" class="top_image" />
                        </div>
                        <div class="py-2 mx-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                            AIチャットボットがBCP策定をサポート
                        </div>
                        <div class="card-body px-5">
                            <div class="card-text">
                                中小企業庁のモデルBCPを参考にした入門・初級・中級・上級までの事業継続計画（BCP）は、AIチャットボットのアドバイスやヒント表示を参考にしながら、策定を進めることができます。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-md-5 mt-3 mt-md-0">
                    <div class="card">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="main-function-image-01" class="top_image" />
                        </div>
                        <div class="py-2 mx-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                            災害応援協定ビジネスマッチング
                        </div>
                        <div class="card-body px-5">
                            <div class="card-text">
                                あらかじめシステムにおいて自社の業種・相互補完情報を登録していただくと、他社が行った検索でヒットすることで、「ヒト」「モノ」の相互補完を効果的にマッチングすることができます。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 p-md-5 mt-3 mt-md-0">
                    <div class="card">
                        <div class="relative d-flex justify-content-center py-3">
                            <i id="main-function-image-01" class="top_image" />
                        </div>
                        <div class="py-2 mx-5 mt-3 mb-1 text-center bg-resilience-red text-light">
                            役員・従業員の家族も合わせて安否確認
                        </div>
                        <div class="card-body px-5">
                            <div class="card-text">
                                役員・従業員等の利用者への安否確認は、システムから直接行います。その後、利用者があらかじめ登録した家族との安否確認も行うことができます。有事の際、家族の安否確認後、BCP発動にしたがって行動することができます。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-md-5">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    setup() {
        const showDetail= (id) =>{
            const url = '/bl/resolve_detail/' + id

            location.href = url;
        };

        return {
            showDetail
        }

    }
}
</script>

<style lang="scss" scoped>
#main-function-image-01{
    background-image: url('../../../svg/01-01.svg');
}
</style>